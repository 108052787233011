<template>
  <div class="mgl-30 mgt-20" style="">
    <!-- paymentConfiguration 支付配置 paymentConfiguration.vue-->
    支付配置 暂未开通
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.mgl-30{
  margin-left: 30px;
}
</style>